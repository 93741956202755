<template>
    <div class="sub-talk episode training">
        <router-link to="/training/main" class="btn-back-main">
            <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
            <svg fill="#000000" height="30px" width="30px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 26.676 26.676" xml:space="preserve"><g><path d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59
                        c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815
                        C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029
                        c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562
                        C26.18,21.891,26.141,21.891,26.105,21.891z"/><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></g></svg>
        </router-link>
        <!-- {{$route.query.list}} -->
        <div class="sub-inner">
            <!-- <div class="sub-search-box">
                <label for="talk-search">
                    <img src="../../../assets/images/icon_search.svg" alt="" />
                </label>
                <input type="text" id="talk-search" />
            </div> -->
            <div class="sub-contents">
                <div class="sub-aside">
                    <ul class="sub-step">
                        <li>
                            <button type="button" data-role="0" @click="_pageMove(0)">
                                <img src="../../assets/images/training/sub_training_icon1.png" alt="">
                                <span>감각</span>
                            </button>
                        </li>
                        <li>
                            <button type="button" data-role="1" @click="_pageMove(1)">
                                <img src="../../assets/images/training/sub_training_icon2.png" alt="">
                                <span>운동</span>
                            </button>
                        </li>
                        <li>
                            <button type="button" data-role="2" @click="_pageMove(2)">
                                <img src="../../assets/images/training/sub_training_icon3.png" alt="">
                                <span>혼합</span>
                            </button>
                        </li>
                    </ul>
                </div>
                <div class="sub-content">
                    <div class="sub-talk-inner">
                        <div class="sub-title">
                            <div class="title-inner">
                                <div class="link-text" :class="{'active': this.activeTitleNumber === '1'}">
                                    <router-link to="/training/sub?episode=1" @click="_menuMove">
                                        <em>혀</em>
                                    </router-link>
                                </div>
                                <div class="link-text" :class="{'active': this.activeTitleNumber === '2'}">
                                    <router-link to="/training/sub?episode=2" @click="_menuMove">
                                        <em>입술</em>
                                    </router-link>
                                </div>
                                <div class="link-text" :class="{'active': this.activeTitleNumber === '3'}">
                                    <router-link to="/training/sub?episode=3" @click="_menuMove">
                                        <em>볼</em>
                                    </router-link>
                                </div>
                                <div class="link-text" :class="{'active': this.activeTitleNumber === '4'}">
                                    <router-link to="/training/sub?episode=4" @click="_menuMove">
                                        <em>구개</em>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="training-option">
                            <button :class="{'active': this.activeSubTabTitle === '전체'}" @click="_subTab('전체')">전체</button>
                            <button :class="{'active': this.activeSubTabTitle === '오른쪽'}" @click="_subTab('오른쪽')">오른쪽</button>
                            <button :class="{'active': this.activeSubTabTitle === '왼쪽'}" @click="_subTab('왼쪽')">왼쪽</button>
                        </div>
                        <div class="talk-box story-video-box">
                            <div class="sub-video-box on">
                                <div class="sub-tab-video-box" :class="{'active' : this.activeSubTabTitle === '전체'}">
                                    <carousel class="video-carousel" :items-to-show="1" ref="myCarousel" @slide-end="_slideEnd">
                                        <slide v-for="sub1 in selectData.all" :key="sub1">
                                            <div class="video">
                                                <vimeo-player ref="player" :video-id="sub1" :player-width="1000" :player-height="750" @ready="true"/>
                                            </div>
                                        </slide>
                                        <template #addons>
                                            <navigation />
                                        </template>
                                    </carousel>
                                </div>
                                <div class="sub-tab-video-box" :class="{'active' : this.activeSubTabTitle === '오른쪽'}">
                                    <carousel class="video-carousel" :items-to-show="1" ref="myCarouse2" @slide-end="_slideEnd">
                                        <slide v-for="sub2 in selectData.right" :key="sub2">
                                            <div class="video">
                                                <vimeo-player ref="player" :video-id="sub2" :player-width="1000" :player-height="750" @ready="true"/>
                                            </div>
                                        </slide>
                                        <template #addons>
                                            <navigation />
                                        </template>
                                    </carousel>
                                </div>
                                <div class="sub-tab-video-box" :class="{'active' : this.activeSubTabTitle === '왼쪽'}">
                                    <carousel class="video-carousel" :items-to-show="1" ref="myCarouse3" @slide-end="_slideEnd">
                                        <slide v-for="sub3 in selectData.left" :key="sub3">
                                            <div class="video">
                                                <vimeo-player ref="player" :video-id="sub3" :player-width="1000" :player-height="750" @ready="true"/>
                                            </div>
                                        </slide>
                                        <template #addons>
                                            <navigation />
                                        </template>
                                    </carousel>
                                </div>
                            </div>
                            <div class="sub-video-box">
                                <div class="sub-tab-video-box" :class="{'active' : this.activeSubTabTitle === '전체'}">
                                    <carousel class="video-carousel" :items-to-show="1" ref="myCarouse4" @slide-end="_slideEnd">
                                        <slide v-for="sub4 in selectData.all" :key="sub4">
                                            <div class="video">
                                                <vimeo-player ref="player" :video-id="sub4" :player-width="1000" :player-height="750" @ready="true"/>
                                            </div>
                                        </slide>
                                        <template #addons>
                                            <navigation />
                                        </template>
                                    </carousel>
                                </div>
                                <div class="sub-tab-video-box" :class="{'active' : this.activeSubTabTitle === '오른쪽'}">
                                    <carousel class="video-carousel" :items-to-show="1" ref="myCarouse5" @slide-end="_slideEnd">
                                        <slide v-for="sub5 in selectData.right" :key="sub5">
                                            <div class="video">
                                                <vimeo-player ref="player" :video-id="sub5" :player-width="1000" :player-height="750" @ready="true"/>
                                            </div>
                                        </slide>
                                        <template #addons>
                                            <navigation />
                                        </template>
                                    </carousel>
                                </div>
                                <div class="sub-tab-video-box" :class="{'active' : this.activeSubTabTitle === '왼쪽'}">
                                    <carousel class="video-carousel" :items-to-show="1" ref="myCarouse6" @slide-end="_slideEnd">
                                        <slide v-for="sub6 in selectData.left" :key="sub6">
                                            <div class="video">
                                                <vimeo-player ref="player" :video-id="sub6" :player-width="1000" :player-height="750" @ready="true"/>
                                            </div>
                                        </slide>
                                        <template #addons>
                                            <navigation />
                                        </template>
                                    </carousel>
                                </div>
                            </div>
                            <div class="sub-video-box">
                                <div class="sub-tab-video-box" :class="{'active' : this.activeSubTabTitle === '전체'}">
                                    <carousel class="video-carousel" :items-to-show="1" ref="myCarouse7" @slide-end="_slideEnd">
                                        <slide v-for="sub7 in selectData.all" :key="sub7">
                                            <div class="video">
                                                <vimeo-player ref="player" :video-id="sub7" :player-width="1000" :player-height="750" @ready="true"/>
                                            </div>
                                        </slide>
                                        <template #addons>
                                            <navigation />
                                        </template>
                                    </carousel>
                                </div>
                                <div class="sub-tab-video-box" :class="{'active' : this.activeSubTabTitle === '오른쪽'}">
                                    <carousel class="video-carousel" :items-to-show="1" ref="myCarouse8" @slide-end="_slideEnd">
                                        <slide v-for="sub8 in selectData.right" :key="sub8">
                                            <div class="video">
                                                <vimeo-player ref="player" :video-id="sub8" :player-width="1000" :player-height="750" @ready="true"/>
                                            </div>
                                        </slide>
                                        <template #addons>
                                            <navigation />
                                        </template>
                                    </carousel>
                                </div>
                                <div class="sub-tab-video-box" :class="{'active' : this.activeSubTabTitle === '왼쪽'}">
                                    <carousel class="video-carousel" :items-to-show="1" ref="myCarouse9" @slide-end="_slideEnd">
                                        <slide v-for="sub9 in selectData.left" :key="sub9">
                                            <div class="video">
                                                <vimeo-player ref="player" :video-id="sub9" :player-width="1000" :player-height="750" @ready="true"/>
                                            </div>
                                        </slide>
                                        <template #addons>
                                            <navigation />
                                        </template>
                                    </carousel>
                                </div>
                            </div>
                        </div>
                        <div class="talk-text-box">
                            <p class="talk-result">
                                {{ activeSubTitle }} - {{ activeSubTabTitle }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import { vueVimeoPlayer } from 'vue-vimeo-player'

const myCarousel = ref(null);

export default {
    name: 'Sub',
    components: {
        Carousel,
        Slide,
        Navigation,
        vueVimeoPlayer
    },
    beforeMount() {
        console.log(this.$cookie.getCookie('training-token'));
        if (this.$cookie.getCookie('training-token') === null) this.$router.push('/');
    },
    data() {
        return {
            activeTitleNumber: 1,
            activeSubTitle: '감각',
            activeSubTabTitle: '전체',
            videoData: {
                training1: {
                    sub1: {
                        all: ['978145209', '978145320'],
                        left: ['978147065', '978147180'],
                        right: ['978147434', '978147509']
                    },
                    sub2: {
                        all: ['978217058', '978215818', '978215905', '978215976', '978216058', '978216157', '978216236', '978216311', '978216387', '978216450', '978216519', '978216592', '978216672', '978216744', '978216923', '978216992'],
                        left: ['983117978', '983118013'],
                        right: ['983118300', '983118371']
                    },
                    sub3: {
                        all: ['983121922', '983121961', '983121875'],
                        left: ['983122184'],
                        right: ['983122318']
                    },
                },
                training2: {
                    sub1: {
                        all: ['983153265', '983153230'],
                        left: ['983154303'],
                        right: ['983154464']
                    },
                    sub2: {
                        all: ['983155102', '983155241', '983155135', '983155165', '983155204'],
                        left: ['983155478'],
                        right: ['983155644']
                    },
                    sub3: {
                        all: ['983155883', '983155910'],
                        left: [],
                        right: []
                    },
                },
                training3: {
                    sub1: {
                        all: ['983162455', '983162412'],
                        left: ['983162652'],
                        right: ['983162854']
                    },
                    sub2: {
                        all: ['983163400', '983163363', '983163320', '983163281'],
                        left: ['983163771', '983163723'],
                        right: ['983164141', '983164110']
                    },
                    sub3: {
                        all: [],
                        left: ['983164700'],
                        right: ['983164971']
                    },
                },
                training4: {
                    sub1: {
                        all: ['983170429'],
                        left: ['983170633'],
                        right: ['983170811']
                    },
                    sub2: {
                        all: ['983171098', '983171022', '983171058'],
                        left: [],
                        right: []
                    },
                    sub3: {
                        all: ['983171325'],
                        left: [],
                        right: []
                    },
                },
            },
            selectData: null,
            selectTitle: null
        }
    },
    updated() {
        if (this.activeSubTabTitle === '전체') {
            if (this.activeSubTitle === '감각') this.$refs.myCarousel.restartCarousel();
            if (this.activeSubTitle === '운동') this.$refs.myCarouse4.restartCarousel();
            if (this.activeSubTitle === '혼합') this.$refs.myCarouse7.restartCarousel();
        }

        if (this.activeSubTabTitle === '오른쪽') {
            if (this.activeSubTitle === '감각') this.$refs.myCarouse2.restartCarousel();
            if (this.activeSubTitle === '운동') this.$refs.myCarouse5.restartCarousel();
            if (this.activeSubTitle === '혼합') this.$refs.myCarouse8.restartCarousel();
        }

        if (this.activeSubTabTitle === '왼쪽') {
            if (this.activeSubTitle === '감각') this.$refs.myCarouse3.restartCarousel();
            if (this.activeSubTitle === '운동') this.$refs.myCarouse6.restartCarousel();
            if (this.activeSubTitle === '혼합') this.$refs.myCarouse9.restartCarousel();
        }
    },
    created() {
        console.log('this.selectData : ', this.selectData)
        this._setData();
    },
    methods: {
        _setData() {
            const _searchParams = new URLSearchParams(location.search);
            const _listNumber = _searchParams.get('episode');

            this.activeTitleNumber = _listNumber;
            this.activeSubTitle = '감각';
            this.activeSubTabTitle = '전체';

            console.log('_searchParams : ', _searchParams);
            console.log('this.activeTitleNumber : ', this.activeTitleNumber);

            if (_searchParams) {
                switch (_listNumber) {
                    case '1':
                        this.selectData = this.videoData.training1.sub1;
                        break;

                    case '2':
                        this.selectData = this.videoData.training2.sub1;
                        break;

                    case '3':
                        this.selectData = this.videoData.training3.sub1;
                        break;

                    case '4':
                        this.selectData = this.videoData.training4.sub1;
                        break;

                    default:
                        this.selectData = this.videoData.training1.sub1;
                        break;
                }
            } else {
                this.selectData = this.videoData.training1.sub1;
            }
        },
        _menuMove() {
            const _this = this;
            setTimeout(() => {
                _this._setData();
            }, 0)
        },
        _pageMove(idx) {
            const optionsButton = document.querySelectorAll('.training-option button');
            const _searchParams = new URLSearchParams(location.search);
            const _listNumber = _searchParams.get('episode');
            console.log('optionsButton : ', optionsButton)

            optionsButton[0].removeAttribute('disabled');
            optionsButton[1].removeAttribute('disabled');
            optionsButton[2].removeAttribute('disabled');

            if (_searchParams) {
                switch (_listNumber) {
                    case '1':
                        if (idx == 0) this.selectData = this.videoData.training1.sub1;
                        if (idx == 1) this.selectData = this.videoData.training1.sub2;
                        if (idx == 2) this.selectData = this.videoData.training1.sub3;
                        break;

                    case '2':
                        if (idx == 0) this.selectData = this.videoData.training2.sub1;
                        if (idx == 1) this.selectData = this.videoData.training2.sub2;
                        if (idx == 2) this.selectData = this.videoData.training2.sub3;
                        break;

                    case '3':
                        if (idx == 0) this.selectData = this.videoData.training3.sub1;
                        if (idx == 1) this.selectData = this.videoData.training3.sub2;
                        if (idx == 2) this.selectData = this.videoData.training3.sub3;
                        break;

                    case '4':
                        if (idx == 0) this.selectData = this.videoData.training4.sub1;
                        if (idx == 1) this.selectData = this.videoData.training4.sub2;
                        if (idx == 2) this.selectData = this.videoData.training4.sub3;
                        break;

                    default:
                        this.selectData = this.videoData.training1.sub1;
                        break;
                }
            } else {
                this.selectData = this.videoData.training1.sub1;
            }

            this.activeSubTabTitle = '전체';
            if (idx === 0) {
                this.activeSubTitle = '감각';
            }
            if (idx === 1) {
                this.activeSubTitle = '운동';

                if (_listNumber == 4) {
                    optionsButton[1].setAttribute('disabled', true);
                    optionsButton[2].setAttribute('disabled', true);
                }
            }
            if (idx === 2) {
                this.activeSubTitle = '혼합';
                if (_listNumber == 2) {
                    optionsButton[1].setAttribute('disabled', true);
                    optionsButton[2].setAttribute('disabled', true);
                }

                if (_listNumber == 3) {
                    optionsButton[0].setAttribute('disabled', true);
                    this.activeSubTabTitle = '오른쪽';
                }

                if (_listNumber == 4) {
                    optionsButton[1].setAttribute('disabled', true);
                    optionsButton[2].setAttribute('disabled', true);
                }
            }

            [].forEach.call(this.$refs.player, function (_video) {
                _video.pause();
            });

            const _subVideoBox = document.querySelectorAll('.sub-video-box');
            _subVideoBox.forEach((_el, i) => {
                _el.classList.remove('on');
                if (Number(idx) === i) _el.classList.add('on');
            });
            // this.selectTitle = this.selectData.title[idx];
        },
        _subTab(tabTitle) {
            this.activeSubTabTitle = tabTitle;

            [].forEach.call(this.$refs.player, function (_video) {
                _video.pause();
            });
        },
        _slideEnd() {
            [].forEach.call(this.$refs.player, function (_video) {
                _video.pause();
            });
        },
    },
}
</script>

<style>
    @import url(../../talk.css);

    .sub-talk.episode.training {
        background: #fff;
    }

    .training .sub-title {
        position: relative;
    }

    .training .sub-title .title-inner {
        border-radius: 20px;
        text-align: center;
        width: 100%;
        background: #77b0ed;
        font-size: 20px;
        margin-bottom: 10px;
        display: flex;
        padding: 10px 0;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .training .sub-title .title-inner .link-text a {
        opacity: 0.4;
        color: #fff;
        text-decoration: none;
        font-style: normal;
    }

    .training .sub-title .title-inner .link-text a em {
        font-style: normal;
    }

    .training .sub-title .title-inner .link-text.active a,
    .training .sub-title .title-inner .link-text:hover a {
        opacity: 0.7;
    }

    .training-option {
        display: flex;
        gap: 20px;
        justify-content: center;
        margin: 20px 0 10px;
    }

    .training-option button {
        width: 150px;
        height: 50px;
        background-color: #77b0ed;
        border-radius: 20px;
        font-size: 18px;
        color: #fff;
    }

    .training-option button:disabled {
        opacity: 0.3;
        background-color: #aaa;
    }

    .training-option button.active {
        background-color: #00438b;
        text-decoration: underline;
    }

    .sub-tab-video-box {
        display: none;
    }

    .sub-tab-video-box.active {
        display: block;
    }

    .sub-talk.training .talk-box .video {
        width: 880px;
    }

    .sub-talk.training .talk-box .video [data-vimeo-initialized] {
        padding: 68.33% 0 0 0;
    }

    @media (max-width: 1440px) {
        .sub-talk.training .talk-box .video {
            width: 61.1111vw;
        }
    }

    @media (max-width: 1024px) {
        .sub-talk.training .talk-box .video {
            width: 47.4444vw;
        }
    }
</style>